.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}

.qrcode__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.qrcode__container--parent {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.qrcode__input {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  margin-top: 20px;
}

.qrcode__input input {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  outline: none;
  resize: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
}

.qrcode__input button,
.qrcode__download button {
  display: inline-block;
  padding: 7px;
  cursor: pointer;
  color: #fff;
  background-color: rgb(96, 96, 246);
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.qrcode__download {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.qrcode__download button {
  margin-top: 10px;
}

.general-table table,
.general-table th,
.general-table td {
  border: 1px solid black;
  border-collapse: collapse;
}

.general-table th,
.general-table td {
  padding: 8px 15px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
